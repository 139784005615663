var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Informações básicas" },
                  },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Título "),
                                  ]),
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid": _vm.$v.item.title.$error,
                                    },
                                    model: {
                                      value: _vm.item.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "title", $$v)
                                      },
                                      expression: "item.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(" #ID - MIGRAR "),
                                      _c("feather-icon", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.v-dark",
                                            modifiers: {
                                              hover: true,
                                              "v-dark": true,
                                            },
                                          },
                                        ],
                                        staticClass: "text-info",
                                        attrs: {
                                          size: "16",
                                          icon: "HelpCircleIcon",
                                          title:
                                            "Cadastre o ID usado no Consys",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.item.id_old,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "id_old", $$v)
                                      },
                                      expression: "item.id_old",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-1",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.description.$error,
                                  },
                                },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Descrição "),
                                  ]),
                                  _c("editor", {
                                    staticClass: "description-editor-1",
                                    attrs: {
                                      "api-key":
                                        "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                      init: this.optionEditor({
                                        height: 600,
                                        selector: "description-editor-1",
                                      }),
                                    },
                                    model: {
                                      value: _vm.item.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "description", $$v)
                                      },
                                      expression: "item.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mt-1 mb-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(" Sobre o produto - "),
                                    _c("small", [
                                      _vm._v("Será exibido no final da págna."),
                                    ]),
                                  ]),
                                  _c("editor", {
                                    staticClass: "about-editor-2",
                                    attrs: {
                                      "api-key":
                                        "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                      init: this.optionEditor({
                                        height: 300,
                                        selector: "about-editor-2",
                                      }),
                                    },
                                    model: {
                                      value: _vm.item.about,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "about", $$v)
                                      },
                                      expression: "item.about",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1 card-header-custom-actions",
                    attrs: { "header-tag": "header" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-11 col-8" }, [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Parcelas & Formas de pagamento "),
                                ]),
                                _c("small", [
                                  _vm._v(
                                    "Cadastre as parcelas e selecione as formas de pagamento"
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-1 col-4" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { 400: true },
                                        },
                                      ],
                                      staticClass: "btn-block text-center",
                                      staticStyle: {
                                        "line-height": "3",
                                        padding: "3px !important",
                                      },
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: _vm.repeateAgainInstallments,
                                      },
                                    },
                                    [_c("i", { staticClass: "bi bi-plus" })]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm.item.installments.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex form-row-header-repeat" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center d-none d-sm-block",
                                    staticStyle: { width: "7%" },
                                  },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Nº"),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { width: "16%" },
                                  },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Valor"),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Parcelas x Valor",
                                        expression: "'Parcelas x Valor'",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass:
                                      "text-center d-none d-sm-block",
                                    staticStyle: { width: "20%" },
                                  },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Total"),
                                    ]),
                                  ]
                                ),
                                _vm._l(
                                  _vm.payment_forms,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "text-center",
                                        staticStyle: { width: "8%" },
                                        attrs: { id: index },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: item.title,
                                                expression: "item.title",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "d-block",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _c("i", {
                                                  staticClass: "bi",
                                                  class: item.icon,
                                                  staticStyle: {
                                                    "font-size": "1.6rem",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "50px",
                                      visibility: "hidden",
                                    },
                                  },
                                  [_vm._v(" a ")]
                                ),
                              ],
                              2
                            ),
                            _c(
                              "b-form",
                              {
                                staticClass:
                                  "repeater-form installments-checkboxs repeater-form-custom",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.repeateAgain.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.item.installments,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "installment-key-" + index,
                                      staticClass:
                                        "d-flex justify-content: space-between; bg",
                                      staticStyle: { "align-items": "center" },
                                      attrs: { id: "installment-id-" + index },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "7%" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(index + 1) + "x"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "16%" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex items-center justify-content-center",
                                            },
                                            [
                                              _c("money", {
                                                staticClass:
                                                  "form-control text-center",
                                                class: {
                                                  "is-invalid":
                                                    _vm.$v.item.installments
                                                      .$each[index].value
                                                      .$error,
                                                },
                                                attrs: { placeholder: "0,00" },
                                                model: {
                                                  value: item.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "value", $$v)
                                                  },
                                                  expression: "item.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: "Parcelas x Valor",
                                              expression: "'Parcelas x Valor'",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            " text-center d-none d-sm-block",
                                          staticStyle: { width: "20%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toCurrency")(
                                                  (index + 1) * item.value
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1 },
                                            model: {
                                              value: item.money,
                                              callback: function ($$v) {
                                                _vm.$set(item, "money", $$v)
                                              },
                                              expression: "item.money",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1 },
                                            model: {
                                              value: item.card_debit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "card_debit",
                                                  $$v
                                                )
                                              },
                                              expression: "item.card_debit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1 },
                                            model: {
                                              value: item.card_credit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "card_credit",
                                                  $$v
                                                )
                                              },
                                              expression: "item.card_credit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1 },
                                            model: {
                                              value: item.pix,
                                              callback: function ($$v) {
                                                _vm.$set(item, "pix", $$v)
                                              },
                                              expression: "item.pix",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1 },
                                            model: {
                                              value: item.check,
                                              callback: function ($$v) {
                                                _vm.$set(item, "check", $$v)
                                              },
                                              expression: "item.check",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1, name: "letter" },
                                            model: {
                                              value: item.letter,
                                              callback: function ($$v) {
                                                _vm.$set(item, "letter", $$v)
                                              },
                                              expression: "item.letter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "8%" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass:
                                              "custom-control-primary course-plots",
                                            attrs: { value: 1, name: "billet" },
                                            model: {
                                              value: item.billet,
                                              callback: function ($$v) {
                                                _vm.$set(item, "billet", $$v)
                                              },
                                              expression: "item.billet",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", {}, [
                                        _c(
                                          "div",
                                          { staticClass: "mb-0 mt-1" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "ripple",
                                                    rawName: "v-ripple.400",
                                                    value:
                                                      "rgba(234, 84, 85, 0.15)",
                                                    expression:
                                                      "'rgba(234, 84, 85, 0.15)'",
                                                    modifiers: { 400: true },
                                                  },
                                                ],
                                                staticStyle: {
                                                  padding: "8px 8px !important",
                                                },
                                                attrs: {
                                                  variant: "outline-danger",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeAtinstallment(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bi bi-trash3",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        )
                      : _c(
                          "p",
                          {
                            staticClass: "mb-0",
                            class: {
                              "text-danger": _vm.$v.item.installments.$dirty,
                            },
                          },
                          [_vm._v(" Cadastre 1 ou mais parcelas ")]
                        ),
                  ]
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Sites de buscas" } },
                  [
                    _c("b-card-text", [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          ' Caso queira personalizar o título, descrição e url para mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar SEO do site" e preencha os campos abaixo. '
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Título SEO"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.title_seo,
                                  expression: "item.title_seo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.title_seo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "title_seo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("URL"),
                              ]),
                              _c(
                                "b-input-group",
                                {
                                  staticClass: "input-group-merge",
                                  attrs: { prepend: "/passaporte/" },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "text-primary pl-1",
                                    model: {
                                      value: _vm.item.slug,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "slug", $$v)
                                      },
                                      expression: "item.slug",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Descrição para SEO"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.description_seo,
                                  expression: "item.description_seo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { maxlength: "320" },
                              domProps: { value: _vm.item.description_seo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "description_seo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Imagem de destaque" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          { staticClass: "form-row" },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("input", {
                                  staticClass: "form-control-file hidden",
                                  attrs: {
                                    id: "image",
                                    type: "file",
                                    accept: "image/*",
                                  },
                                  on: { change: _vm.previewImage },
                                }),
                                _c("b-form-group", [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "d-block text-center",
                                      staticStyle: {
                                        border: "1px solid rgb(222 222 222)",
                                        "border-radius": "4px",
                                        "border-style": "dashed",
                                        cursor: "pointer",
                                        "font-size": "1.1rem",
                                      },
                                      attrs: { for: "image" },
                                    },
                                    [
                                      !_vm.preview && !_vm.image_url
                                        ? _c(
                                            "div",
                                            { staticClass: "pt-5 py-4" },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  size: "19",
                                                  icon: "ImageIcon",
                                                },
                                              }),
                                              _vm._v(" Selecionar imagem "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.preview
                                        ? [
                                            _c("b-img", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                for: "image",
                                                src: _vm.preview,
                                                fluid: "",
                                              },
                                            }),
                                          ]
                                        : !_vm.preview && _vm.image_url
                                        ? [
                                            _c("b-img", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                for: "image",
                                                src: _vm.image_url,
                                                fluid: "",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", [
                                      _vm._v("Vídeo de apresentação"),
                                    ]),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: "Link do YouTube/Vimeo",
                                      },
                                      model: {
                                        value: _vm.item.video_url,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "video_url", $$v)
                                        },
                                        expression: "item.video_url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("label", [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Label da imagem "),
                                ]),
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.label_image.$error,
                                      },
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.item.label_image,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "label_image", $$v)
                                        },
                                        expression: "item.label_image",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "small",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: { "margin-top": "4px" },
                                  },
                                  [
                                    _vm._v(
                                      " Será exibido sobre a imagem de destaque. "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Detalhes" } },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Tempo de acesso "),
                                _c("small", [_vm._v("(dias) ")]),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["####"],
                                    expression: "['####']",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.validity,
                                    expression: "item.validity",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.item.validity.$error,
                                },
                                attrs: {
                                  type: "tel",
                                  min: "1",
                                  placeholder: "365",
                                },
                                domProps: { value: _vm.item.validity },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "validity",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Subcategoria "),
                              ]),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectSub",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.subcategory.$error,
                                      },
                                      attrs: {
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Digite o título",
                                        options: _vm.optionsSubcategories,
                                      },
                                      on: { search: _vm.fetchSubcategories },
                                      model: {
                                        value: _vm.item.subcategory,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "subcategory", $$v)
                                        },
                                        expression: "item.subcategory",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectSub.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-12 col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Sales force "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    ref: "selectSF",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.salesforce.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsSalesForce,
                                    },
                                    on: { search: _vm.fetchSalesForce },
                                    model: {
                                      value: _vm.item.salesforce,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "salesforce", $$v)
                                      },
                                      expression: "item.salesforce",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.selectSF.open = false
                                          },
                                        },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Carga horária e Ficha técnica" },
                  },
                  [
                    _c("b-card-text", [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["##:##", "###:##", "####:##"],
                              expression: "['##:##', '###:##', '####:##']",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.workload,
                              expression: "item.workload",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.item.workload.$error },
                          attrs: { type: "tel", min: "1" },
                          domProps: { value: _vm.item.workload },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "workload",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-select",
                            {
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.optionsDatasheets,
                              },
                              on: { search: _vm.fetchDatasheets },
                              model: {
                                value: _vm.item.datasheet,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "datasheet", $$v)
                                },
                                expression: "item.datasheet",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Opcionais" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-row", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-12 mb-1" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Depoimento(s) "),
                                  _c("small", [_vm._v("(Aprovados)")]),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    ref: "selectApproved",
                                    attrs: {
                                      multiple: "",
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsApproveds,
                                    },
                                    on: { search: _vm.fetchApproveds },
                                    model: {
                                      value: _vm.item.approveds,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "approveds", $$v)
                                      },
                                      expression: "item.approveds",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.selectApproved.open = false
                                          },
                                        },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_store_discount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_store_discount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.disable_store_discount",
                                      },
                                    },
                                    [_vm._v(" Não aplicar desconto em loja ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_billet,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_billet",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_billet",
                                      },
                                    },
                                    [_vm._v(" Desabilitar venda no boleto ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_popup,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_popup",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_popup",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Desativar Pop-up de interessado "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_form_contact,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_form_contact",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_form_contact",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Desativar formulário de contato "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.plataforma_questoes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "plataforma_questoes",
                                            $$v
                                          )
                                        },
                                        expression: "item.plataforma_questoes",
                                      },
                                    },
                                    [_vm._v(" Acesso a Plataf. de Questões ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Tags" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-tags", {
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.$v.item.tags.$error,
                                      },
                                      attrs: {
                                        separator: ",;",
                                        "no-add-on-enter": "",
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.item.tags,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "tags", $$v)
                                        },
                                        expression: "item.tags",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mb-0 mt-2" }, [
                          _vm._v("Adicione tags separando-as por vírgula."),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Status" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-0" },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid": _vm.$v.item.status.$error,
                              },
                              attrs: {
                                label: "title",
                                options: [
                                  { title: "Ativo", code: 1 },
                                  { title: "Inativo", code: 0 },
                                ],
                              },
                              model: {
                                value: _vm.item.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "status", $$v)
                                },
                                expression: "item.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("ButtonsActionsFooter", {
                  attrs: {
                    variant: "success",
                    submited: _vm.submited,
                    text: "Salvar",
                    subtext: "Aguarde...",
                  },
                }),
                _c("p", { staticClass: "mt-2" }, [
                  _c("i", { staticClass: "bi bi-plug" }),
                  _vm._v(" ID Vindi: " + _vm._s(_vm.item.external_id)),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "createDiscipline",
            centered: "",
            title: "Selecionar disciplina",
            "hide-footer": "",
            size: "lg",
          },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitFindDisciplines.apply(null, arguments)
                },
              },
            },
            [
              _c("b-card-text", [
                _c(
                  "div",
                  { staticClass: "form-row justify-content-between" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "align-items-center justify-content-left",
                        attrs: { cols: "12", md: "4" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tag Tema"),
                            ]),
                            _c(
                              "v-select",
                              {
                                ref: "selectTema",
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsTagTema,
                                },
                                on: { search: _vm.fetchTagsCategoryTema },
                                model: {
                                  value: _vm.filters.tag_subject,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "tag_subject", $$v)
                                  },
                                  expression: "filters.tag_subject",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.selectTema.open = false
                                      },
                                    },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-none d-sm-block align-items-center justify-content-left",
                        attrs: { cols: "6", md: "4" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tag Concurso"),
                            ]),
                            _c(
                              "v-select",
                              {
                                ref: "selectConcurso",
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsTagConcurso,
                                },
                                on: { search: _vm.fetchTagsCategoryConcurso },
                                model: {
                                  value: _vm.filters.tag_contest,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "tag_contest", $$v)
                                  },
                                  expression: "filters.tag_contest",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.selectConcurso.open = false
                                      },
                                    },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("b-col", { attrs: { cols: "6", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tag Linha do tempo"),
                          ]),
                          _c(
                            "v-select",
                            {
                              ref: "selectLinhadoTempo",
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.optionsTagLinhaDoTempo,
                              },
                              on: { search: _vm.fetchTagsCategoryLinhaDoTempo },
                              model: {
                                value: _vm.filters.tag_timeline,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "tag_timeline", $$v)
                                },
                                expression: "filters.tag_timeline",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectLinhadoTempo.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "8" } },
                      [
                        _c("b-form-group", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Título da disciplina"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.title,
                                expression: "filters.title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "Digite o título da disciplina",
                            },
                            domProps: { value: _vm.filters.title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { cols: "6", md: "4" } }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { for: "" },
                          },
                          [_vm._v("Buscar")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-block",
                            attrs: {
                              type: "submit",
                              disabled: _vm.submitedFind,
                            },
                          },
                          [
                            _vm.submitedFind
                              ? _c(
                                  "div",
                                  [
                                    _c("b-spinner", {
                                      staticClass: "left",
                                      attrs: { small: "", variant: "light" },
                                    }),
                                    _vm._v(" buscando... "),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "SearchIcon" },
                                    }),
                                    _vm._v(" BUSCAR "),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    !_vm.dataItems.length
                      ? _c(
                          "div",
                          { staticClass: "area-lessons d-block mt-2" },
                          [
                            _vm._v(
                              " Nenhum registro encontrato até o momento. "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-list-group",
                              { staticClass: "area-lessons mb-1" },
                              _vm._l(_vm.dataItems, function (row, index) {
                                return _c("b-list-group-item", { key: index }, [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-md-11",
                                        staticStyle: { "line-height": "2" },
                                      },
                                      [
                                        _c("span", { staticClass: "mr-1" }, [
                                          _c("i", {
                                            staticClass: "bi bi-card-list",
                                          }),
                                        ]),
                                        _c("span", [
                                          _vm._v(" " + _vm._s(row.title) + " "),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-md-1" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-block btn-sm btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.AddItemToArray(row)
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "PlusCircleIcon",
                                              size: "16",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ])
                              }),
                              1
                            ),
                            _c("CustomPaginateTable", {
                              attrs: {
                                rowsTable: _vm.rowsTable,
                                currentPage: _vm.currentPage,
                                perPage: _vm.perPage,
                                totalRows: _vm.totalRows,
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }